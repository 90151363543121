exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-become-a-collector-form-jsx": () => import("./../../../src/pages/become-a-collector-form.jsx" /* webpackChunkName: "component---src-pages-become-a-collector-form-jsx" */),
  "component---src-pages-collection-center-near-me-jsx": () => import("./../../../src/pages/collection-center-near-me.jsx" /* webpackChunkName: "component---src-pages-collection-center-near-me-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-download-waste-2-value-app-jsx": () => import("./../../../src/pages/download-waste-2-value-app.jsx" /* webpackChunkName: "component---src-pages-download-waste-2-value-app-jsx" */),
  "component---src-pages-faq-page-jsx": () => import("./../../../src/pages/faq-page.jsx" /* webpackChunkName: "component---src-pages-faq-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiatives-jsx": () => import("./../../../src/pages/initiatives.jsx" /* webpackChunkName: "component---src-pages-initiatives-jsx" */),
  "component---src-pages-ocean-waste-management-jsx": () => import("./../../../src/pages/ocean-waste-management.jsx" /* webpackChunkName: "component---src-pages-ocean-waste-management-jsx" */),
  "component---src-pages-other-initiatives-jsx": () => import("./../../../src/pages/other-initiatives.jsx" /* webpackChunkName: "component---src-pages-other-initiatives-jsx" */),
  "component---src-pages-pet-recycling-jsx": () => import("./../../../src/pages/pet-recycling.jsx" /* webpackChunkName: "component---src-pages-pet-recycling-jsx" */),
  "component---src-pages-pilgrimage-waste-management-jsx": () => import("./../../../src/pages/pilgrimage-waste-management.jsx" /* webpackChunkName: "component---src-pages-pilgrimage-waste-management-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-yarn-jsx": () => import("./../../../src/pages/product-yarn.jsx" /* webpackChunkName: "component---src-pages-product-yarn-jsx" */),
  "component---src-pages-products-filament-jsx": () => import("./../../../src/pages/products-filament.jsx" /* webpackChunkName: "component---src-pages-products-filament-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

